import { render, staticRenderFns } from "./return-point-view.vue?vue&type=template&id=78c64fc3"
import script from "./return-point-view.vue?vue&type=script&lang=js"
export * from "./return-point-view.vue?vue&type=script&lang=js"
import style0 from "./return-point-view.vue?vue&type=style&index=0&id=78c64fc3&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports